






































































import {Component, Vue} from "vue-property-decorator";
import GroupCardVerifyRecordAPI from "@/common/api/groupCardVerifyRecord";
import {getPageAPIs, Util} from "@/common/util";
export const pageApiList = getPageAPIs(GroupCardVerifyRecordAPI);

@Component({})
export default class UserList extends Vue {
  private classApi: any = new GroupCardVerifyRecordAPI();
  private form: any = {
    receipt_code: '',
  };
  private time: any = []
  private balanceNumber:string = ''
  private isShowBalanceNumber:boolean = false

  private activated(): void {
    this.getTable();
  }

  private getTable(): void {
    if(this.time &&this.time.length){
      this.form['start_date'] =this.time[0];
      this.form['end_date'] = this.time[1];
    }
    this.classApi.list(
        {
          ...this.form,
          page: this.tablePage.pageNo,
          limit: this.tablePage.pageSize,
        },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.totalCount = res.data.count;
          this.tablePage.pageNo = res.data.page;
          this.tablePage.pageSize = res.data.prepage;
        }
    );
  }


  private getBalance(phone:string): void {
    this.classApi.balance({phone}, (res: any) => {
      console.log('单店卡余额',res)
      this.balanceNumber = res.data.shop_money
      this.isShowBalanceNumber = true
    });
  }

  private visible: boolean = false;

  private handleClose() {
    this.visible = false;
    this.add_form = new Util().clearObject(this.add_form);
  }

  private addhis() {
    this.add_form = new Util().clearObject(this.add_form);
    this.visible = true;
    this.isShowBalanceNumber = false
    this.balanceNumber = ''
  }
  private onInputPhone(value:string){
    this.add_form.phone=value.replace(/^(0+)|[^\d]+/g,'')
    if(this.add_form.phone.length === 11){
      this.getBalance(this.add_form.phone)
    }else{
      this.isShowBalanceNumber = false
    }
  }
  private add_submit(): void {
    this.$refs['ruleForm'].validate((valid) => {
      if (valid) {
        this.classApi.add(this.add_form, (res: any) => {
          Util.showToast("扣款成功");
          this.getTable()
          this.visible = false;
        })
      } else {
        console.log('error submit!!');
        return false;
      }
    });

  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);
    this.time = []
    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
