import { BasicsClass } from "@/common/BasicsClass";

export default class GroupCardVerifyRecordAPI extends BasicsClass {
  public apiUrls: any = new Map([
    ['getList', {
      url: '/orderOpenClassify/list',
      name: 'list',
      label: '列表'
    }],
  ])
  // 团购券核销记录
  public list(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
    const apiUrls = this.apiUrls
    const url = apiUrls.get('getList').url
    this.BasicPost(
        url,
      {
        ...params,
      },
      false,
      false,
      false,
      successCb,
      failCb,
      showLoading
    );
  }
}
